import { useContext } from '@nuxtjs/composition-api'

type PromiseCallback<T> = (...args: any[]) => Promise<T>

/**
 * Promise wrapped this composable can be running only once in the same period of time
 */
export function useSyncPromise<T> (key: ((...args: any[]) => string) | string, promiseCallback: PromiseCallback<T>): (...args: any[]) => Promise<T> {
  const { app } = useContext()

  if (!app.useSyncPromiseStorage) {
    app.useSyncPromiseStorage = {}
  }

  const storage: Record<string, Promise<T>> = app.useSyncPromiseStorage

  function getKey (...args: any[]): string {
    if (typeof key === 'string') { return key } else if (typeof key === 'function') { return key(...args) }
  }

  return async function (...args: any[]): Promise<T> {
    const key = getKey(...args)
    if (storage[key] !== undefined) {
      return await storage[key]
    }

    return await (storage[key] = promiseCallback(...args).finally(() => {
      storage[key] = undefined
    }))
  }
}


import { SfLoader } from '@storefront-ui/vue'
import { defineComponent, PropType } from '@nuxtjs/composition-api'
import { MegaMenuItem } from '~/modules/mega-menu'

export default defineComponent({
  name: 'MenuDropdown',
  components: {
    SfLoader
  },
  props: {
    items: {
      type: Array as PropType<MegaMenuItem[]>,
      default: null
    },
    activeItem: {
      type: Object as PropType<MegaMenuItem>,
      default: null
    },
    loading: {
      type: Boolean as PropType<boolean>,
      default: false
    }
  },
  emits: ['hover:in', 'item:click'],
  setup (props) {
    function isActive (item: MegaMenuItem): boolean {
      return props.activeItem === item
    }

    function hasChildren (item: MegaMenuItem): boolean {
      return !!item?.children.length
    }

    return {
      isActive,
      hasChildren
    }
  }
})


import { createPopper, Instance } from '@popperjs/core/lib/popper-lite'
import { ComputedRef, ref, useFetch, watch, defineComponent } from '@nuxtjs/composition-api'
import { entries } from 'lodash-es'

import MenuItem from './MenuItem.vue'
import MenuButton from './MenuButton.vue'
import MenuDropdown from './MenuDropdown.vue'
import { MegaMenuItem, MegaMenuTabsEntries } from '~/modules/mega-menu/interfaces'

import { safeComputed } from '~/modules/helpers/composition/safeComputed'
import { useMegaMenu } from '~/modules/mega-menu/composables/useMegaMenu'

export default defineComponent({
  name: 'MegaMenu',
  components: {
    MenuItem,
    MenuButton,
    MenuDropdown
  },
  setup () {
    const { tabs, links, load: loadMegaCategories } = useMegaMenu()

    const megaMenuItems = ref(null)
    const dropdown = ref(null)
    const subDropdown = ref(null)

    const dropdownPopper = ref<Instance>(null)
    const subDropdownPopper = ref<Instance>(null)

    const activeTab = ref('products')

    const activeCategory = ref<MegaMenuItem | undefined>()
    const activeSubCategory = ref(null)
    const activeCategoryItems = safeComputed(() => activeCategory.value?.children ?? [])
    const activeSubItems = safeComputed(() => activeSubCategory.value?.children ?? [])
    const tabsArray: ComputedRef<MegaMenuTabsEntries> = safeComputed(() => entries(tabs.value))

    useFetch(async () => {
      await loadMegaCategories(true)
    })

    function selectTab (tabId) {
      activeTab.value = tabId
    }

    function setInstanceEventListener (instance: Instance, value: boolean) {
      instance.setOptions(options => ({
        ...options,
        modifiers: [
          ...options.modifiers,
          { name: 'eventListeners', enabled: value }
        ]
      }))
    }

    function handleHover ({ item, el } : {item : MegaMenuItem, el: HTMLElement}) {
      if (activeTab.value !== 'products') return

      activeCategory.value = item
      destroyCategoryDropdown()

      if (megaMenuItems.value && dropdown.value) {
        dropdownPopper.value = createPopper(megaMenuItems.value, dropdown.value.$el, {
          placement: 'bottom',
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [128, 8]
              }
            },
            {
              name: 'preventOverflow',
              options: {
                boundary: megaMenuItems.value.$el
              }
            }
          ]
        })

        dropdownPopper.value.state.elements.reference = el
        setInstanceEventListener(dropdownPopper.value, true)
        dropdownPopper.value.update()
      }

      if (item.childrenCount && !item.children.length) {
        loadMegaCategories(false)
      }
    }

    function destroyCategoryDropdown () {
      if (dropdownPopper.value) {
        setInstanceEventListener(dropdownPopper.value, false)
        dropdownPopper.value.destroy()
      }
    }

    function handleCategoryHover ({ item, el }) {
      activeSubCategory.value = item
      destroySubCategoryDropdown()

      if (subDropdown.value && dropdown.value) {
        subDropdownPopper.value = createPopper(dropdown.value.$el, subDropdown.value.$el, {
          placement: 'right-start',
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [-24, 12]
              }
            }
          ]
        })

        subDropdownPopper.value.state.elements.reference = el
        setInstanceEventListener(subDropdownPopper.value, true)
        subDropdownPopper.value.update()
      }
    }

    function destroySubCategoryDropdown () {
      if (subDropdownPopper.value) {
        setInstanceEventListener(subDropdownPopper.value, false)
        subDropdownPopper.value.destroy()
      }
    }

    function isActive (item) {
      return activeTab.value === 'products' && item?.id === activeCategory.value?.id
    }

    function resetMenuToDefault () {
      activeTab.value = 'products'
      activeCategory.value = null
      activeSubCategory.value = null
      destroyCategoryDropdown()
      destroySubCategoryDropdown()
    }

    watch(activeTab, () => {
      activeCategory.value = null
    })

    watch(activeCategory, () => {
      activeSubCategory.value = null
    })

    watch(tabs, () => {
      if (activeCategory.value && tabs.value.produducts) {
        activeCategory.value = tabs.value.produducts.find(el => el.path === activeCategory.value.path)
      }
    })

    return {
      tabs,
      tabsArray,
      links,

      activeTab,
      activeCategory,
      activeSubCategory,
      activeCategoryItems,
      activeSubItems,

      megaMenuItems,
      dropdown,
      subDropdown,

      resetMenuToDefault,
      selectTab,
      isActive,
      handleCategoryHover,
      handleHover
    }
  }
})

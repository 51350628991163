<template>
  <base-button
    aria-label="Category menu button"
    class="menu-button"
    @click="toggleMegaMenuSidebar()"
  >
    <i class="menu-button__icon icon-menu" />
  </base-button>
</template>

<script>
import BaseButton from '~/components/BaseButton'

import { useUiState } from '~/composables'

export default {
  name: 'MenuButton',
  components: {
    BaseButton
  },
  setup () {
    const { toggleMegaMenuSidebar } = useUiState()

    return {
      toggleMegaMenuSidebar
    }
  }
}
</script>

<style lang="scss" scoped>
.menu-button {
  --button-width: 56px;
  --button-height: 48px;

  --button-padding: 12px 16px;

  --button-background: var(--c-primary);
  --button-color: var(--c-white);

  --button-font-size: 24px;

  --button-border-radius: 0 40px 40px 0;
}
</style>

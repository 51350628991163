
import { computed, PropType, defineComponent } from '@nuxtjs/composition-api'
import { CacheTagPrefix, useCache } from '@zaprooecommerce/vsf-cache'
import { useImage } from '~/composables'
import { MegaMenuItem } from '~/modules/mega-menu'
import { MegaMenuItemTypes } from '~/modules/mega-menu/interfaces/MegaMenuItemTypes'
import { SEASON_CATEGORY_NAME } from '~/modules/mega-menu/config/SeasonCategoryName'

export default defineComponent({
  name: 'MenuItem',
  props: {
    item: {
      type: Object as PropType<MegaMenuItem>,
      default: () => ({})
    }
  },
  emits: ['hover:in', 'hover:out', 'click'],
  setup (props, { emit }) {
    const { getMagentoImage } = useImage()
    const { addTags } = useCache()

    if (props.item.type === MegaMenuItemTypes.category && props.item.uid?.length) {
      addTags(
        props.item.uid?.map(uid => ({ prefix: CacheTagPrefix.Category, value: uid }))
      )
    }
    const componentIs = computed(() => props.item && props.item.uid?.includes(SEASON_CATEGORY_NAME) ? 'div' : 'router-link')
    const attributes = computed(() => componentIs.value === 'router-link'
      ? {
          to: props.item.link
        }
      : undefined
    )
    const handlers = computed(() => componentIs.value === 'router-link'
      ? {
          click () {
            emit('click')
          }
        }
      : undefined
    )

    return { getMagentoImage, componentIs, attributes, handlers }
  }
})

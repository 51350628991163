import { MegaMenuItem } from '~/modules/mega-menu/interfaces'
import { MegaMenuItemTypes } from '~/modules/mega-menu/interfaces/MegaMenuItemTypes'

export const MEGA_MENU_SERVICES: MegaMenuItem[] = [
  {
    title: 'Mööbli montaaž',
    type: MegaMenuItemTypes.service,
    icon: '/services/furniture-assembly.webp',
    link: '/teenused/moobli-montaaz.html'
  },
  {
    title: 'Mööbli utiliseerimine',
    type: MegaMenuItemTypes.service,
    icon: '/services/furniture-utilization.webp',
    link: '/teenused/moobli-utiliseerimine.html'
  },
  {
    title: 'Transport',
    type: MegaMenuItemTypes.service,
    icon: '/services/transport.webp',
    link: '/teenused/transport.html'
  },
  {
    title: 'Järelmaks',
    type: MegaMenuItemTypes.service,
    icon: '/services/payment-by-installments.webp',
    link: '/teenused/jarelmaks.html'
  },
  {
    title: 'Kinkekaart',
    type: MegaMenuItemTypes.service,
    icon: '/services/gift-card.webp',
    link: '/c/kinkekaart.html'
  }
  // {
  //   title: 'Sisekujundus teenus',
  //   icon: '/services/interior-design.webp',
  //   link: '/services/interior-design.html'
  // }
]

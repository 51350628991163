import { AgnosticCategoryTree } from '@vue-storefront/core'
import { CategoryTree } from '@vue-storefront/magento-api'
import { map, sumBy } from 'lodash-es'
import { htmlDecode } from '../htmlDecoder'
import { MegaMenuCategoryTree } from '~/modules/mega-menu'

export function buildCategoryTree (rootCategory: CategoryTree | MegaMenuCategoryTree, currentCategory: string, withProducts = false): AgnosticCategoryTree {
  const hasChildren: boolean = Array.isArray(rootCategory.children) && rootCategory.children.length > 0
  const isCurrent: boolean = rootCategory.uid === currentCategory
  const label: string = htmlDecode(rootCategory.name)
  const slug: string = `/${rootCategory.url_path}${rootCategory.url_suffix || ''}`
  const image: string = rootCategory.image

  const childrenUid: string[] = hasChildren
    ? map(rootCategory.children, 'uid') || []
    : []

  const childProductCount: number = hasChildren
    ? sumBy(rootCategory.children, item => item.product_count || 0)
    : 0

  const items: AgnosticCategoryTree[] = hasChildren
    ? rootCategory
      .children
      .filter(c => (withProducts ? c.product_count > 0 : true))
      .filter(c => !!c.include_in_menu)
      .map(c => buildCategoryTree(c, currentCategory))
    : []

  return {
    label,
    slug,
    image,
    uid: [rootCategory.uid, ...childrenUid],
    items: items.filter(c => c.count > 0),
    count: childProductCount || rootCategory.product_count,
    childrenCount: (rootCategory as MegaMenuCategoryTree).childrenCount ?? 0,
    isCurrent
  }
}

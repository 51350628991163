import { AgnosticCategoryTree } from '@vue-storefront/core'
import { MegaMenuItem } from '~/modules/mega-menu'
import { UseContextReturn } from '~/types/core'
import { MegaMenuItemTypes } from '~/modules/mega-menu/interfaces/MegaMenuItemTypes'

export function prepareMenuItems (items: AgnosticCategoryTree[], ctx: UseContextReturn, parent?: MegaMenuItem): MegaMenuItem[] {
  return items.map((item: AgnosticCategoryTree) => {
    const megaMenuItem: MegaMenuItem = {
      id: Symbol(item.slug),
      uid: item.uid as string[],
      type: MegaMenuItemTypes.category,
      title: item.label,
      path: item.slug.replace('.html', '').substring(1),
      link: ctx.localePath('/c' + item.slug),
      icon: item.image as string ?? null,
      parent: null,
      children: [],
      childrenCount: item.childrenCount as number || 0
    }

    megaMenuItem.children = prepareMenuItems(item.items || [], ctx, megaMenuItem)
    return megaMenuItem
  })
}

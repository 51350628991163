import { MegaMenuLink } from '~/modules/mega-menu/interfaces/MegaMenuLink'

export const MEGA_MENU_LINKS: MegaMenuLink[] = [
  {
    title: 'Campaigns',
    url: '/c/kampaaniad.html'
  },
  {
    title: 'New products',
    url: '/c/uued-tooted.html'
  },
  {
    title: 'Gift cards',
    url: '/c/kinkekaart.html'
  }
]
